<mat-card class="content">
  <div *ngIf="this.hasJobId">
    <mat-card-header>
      <mat-card-title>Job Details</mat-card-title>
    </mat-card-header>
    <ul>
      <li>{{this.job.title}}</li>
      <li>{{this.job.intro}}</li>
      <li>{{this.job.location}}</li>
    </ul>
    <hr/>
    <br/>
  </div>
  <mat-card-header>
    <mat-card-title>Candidate Information</mat-card-title>
  </mat-card-header>
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step label="Candidate Details" [stepControl]="candidateForm">
      <form [formGroup]="candidateForm" (ngSubmit)="onCandidateSubmit()">
        <mat-card-content>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="First name" formControlName="firstName">
                <mat-error *ngIf="candidateForm.controls['firstName'].hasError('required')">
                  First name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Last name" formControlName="lastName">
                <mat-error *ngIf="candidateForm.controls['lastName'].hasError('required')">
                  Last name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Email" formControlName="email">
                <mat-error *ngIf="candidateForm.controls['email'].hasError('required')">
                  Email address is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Phone" formControlName="phone">
                <mat-error *ngIf="candidateForm.controls['phone'].hasError('required')">
                  Phone number is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="!this.hasJobId" class="row">
            <mat-form-field class="half-width">
              <mat-select placeholder="Title" formControlName="jobTitle">
                <mat-option *ngFor="let jobTitle of jobTitles" [value]="jobTitle.name">
                  {{ jobTitle.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="candidateForm.controls['jobTitle'].hasError('required')">
                Job Title is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="this.hasJobId" class="row">
            <mat-form-field class="half-width">
              <input matInput disabled placeholder="{{this.candidate.jobTitle}}">
            </mat-form-field>
          </div>
        </mat-card-content>
        <button mat-button matStepperNext [disabled]="!candidateForm.valid">Next</button>
      </form>
    </mat-step>
    <mat-step label="Upload your Resume (Optional)" [stepControl]="resumeForm">
      <form [formGroup]="resumeForm">
        <mat-card-content>
          <div class="full-width" style="margin: 32px 0">
            <label for="file">File: &nbsp;&nbsp;</label>
            <input type="file" (change)="upload($event)" accept=".png,.jpg,.doc,.docx,.pdf" />
            <div class="result" *ngIf="uploadURL | async; let uploadSrc" >
              <a [href]="uploadSrc">You just uploaded a file!</a>
            </div>
            <mat-progress-bar [value]="(uploadProgress | async)" style="margin-top: 16px"></mat-progress-bar>  
          </div>
          <div>
            <!-- <button mat-button (click)="stepper.reset()">Reset Page</button> -->
          </div>
        </mat-card-content>
      </form>
    </mat-step>
    <mat-step label="Additional Information" [stepControl]="moreInfoForm">
      <form [formGroup]="moreInfoForm" (ngSubmit)="onFullSubmit()">
        <mat-card-content>
          <div class="radioDiv">
            <p>Have you previously worked in the same industry?</p>
            <mat-radio-group formControlName="previousExperience">
              <mat-radio-button class="radioMargin" value="Yes">Yes</mat-radio-button>
              <br/>
              <mat-radio-button class="radioMargin" value="No">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="radioDiv">
            <p>Type of work you are seeking:</p>
            <mat-radio-group formControlName="employmentType">
              <mat-radio-button class="radioMargin" value="Full-time">Full-time</mat-radio-button>
              <br/>
              <mat-radio-button class="radioMargin" value="Part-time">Part-time</mat-radio-button>
              <br/>
              <mat-radio-button class="radioMargin" value="Seasonal">Seasonal</mat-radio-button>
              <br/>
              <mat-radio-button class="radioMargin" value="Any">Any</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="radioDiv">
            <p>Are you legally eligible to work in the US?</p>
            <mat-radio-group formControlName="eligibility">
              <mat-radio-button class="radioMargin" value="Yes">Yes</mat-radio-button>
              <br/>
              <mat-radio-button class="radioMargin" value="No">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="radioDiv">
            <p>Are you over the age of 18?</p>
            <mat-radio-group formControlName="isOver18">
              <mat-radio-button class="radioMargin" value="Yes">Yes</mat-radio-button>
              <br/>
              <mat-radio-button class="radioMargin" value="No">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <button mat-button matStepperNext>Submit Page</button>
            <button mat-button (click)="stepper.reset()">Reset Page</button>
          </div>
        </mat-card-content>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</mat-card>

