import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Candidate } from './candidate';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  candidateRef: any;

  constructor(private db: AngularFirestore) { 
    this.candidateRef = db.collection('candidates');
  }

  getCandidate(id: string): Observable<Candidate> {
    return this.candidateRef.doc(id).valueChanges({ idField: 'id' });
  }

  getCandidates(): Observable<Candidate[]> {
    return this.candidateRef.valueChanges({idField: 'id'}) as Observable<Candidate[]>;
  }
  
  async addCandidate(candidate: Candidate) {
    const doc_ref = await this.candidateRef.add(candidate);
    // console.log(doc_ref);
    candidate.id = doc_ref.id;
    this.updateCandidate(candidate);
  }

  removeCandidate(candidate: Candidate) {
    this.candidateRef.doc(candidate.id).delete();
  }

  updateCandidate(candidate: Candidate): void {
    this.candidateRef.doc(candidate.id).set(candidate, { merge: true });
  }

}
