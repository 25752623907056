<div fxFlexFill cdkScrollable class="content">
    <div fxLayout="row">
        <mat-toolbar class="main-header mat-elevation-z4" style="cursor: pointer;" [class.hidden]="toggleSearch" (click)="openSearch()">
            <mat-icon style="margin-left:8px;">search</mat-icon>
        </mat-toolbar>

        <mat-toolbar class="search-block mat-elevation-z4" [class.active]="toggleSearch">
                <mat-toolbar-row style="padding: 0 5px;">
                    <button class="search-icon" mat-icon-button disabled>
                <mat-icon>search</mat-icon>
            </button>
            <input class="search-control" type="text" placeholder="Search" [(ngModel)]="searchText" name="search" #searchbar>
            <button mat-button mat-icon-button (click)="searchClose()" style="color: black">
                <mat-icon>close</mat-icon>
            </button> 
        </mat-toolbar-row>
        </mat-toolbar>
    </div>
    <div fxLayout="row" style="height:800px;background-image: url('./assets/chefs.jpeg');
                               background-repeat: no-repeat;
                               background-size: cover;
                               width: 100vw;
                               background-color: #33333333;
                               background-blend-mode: multiply;">
      <div fxFlex="50"></div>
      <div fxLayout="column" style="color: white; margin:150px 32px;">
        <h2 class="mat-display-4">OPPORTUNITY & GROWTH FOR ALL</h2>
        <h3 class="mat-display-1">No matter where you're starting from, you can grow from here.</h3>
      </div>
    </div>
    <div style="margin: 64px;"></div>
    <div fxLayout="column">
      <mat-card *ngFor="let job of jobs | filter: searchText" style="margin: 16px;" >
        <img mat-card-image style="max-height: 300px;object-fit: cover;" [src]="job.imageUrl">
        <!-- <app-job *ngIf="isEdit" [job]="job" [edit]="isEdit" (jobSaved)="isEdit=!isEdit"></app-job> -->
          <div fxLayout="row wrap" *ngIf="!isEdit" class="content">
            <div fxFlex>
            <mat-card-title>{{job.title}}</mat-card-title>
            <mat-card-subtitle>{{job.location}}</mat-card-subtitle>
            <mat-card-content>{{job.intro}}</mat-card-content>
            <mat-card-content>{{job.responsibilities}}</mat-card-content>
            <mat-card-content>{{job.additionalResponsibilities}}</mat-card-content>
          </div>
          </div>
            <mat-card-actions *ngIf="!isEdit">
              <div class="content">
                <button (click)="navigateTo('resume/'+job.id)" mat-raised-button color="primary">Apply</button>
                <button (click)="navigateTo('job/'+job.id)" *ngIf="canEdit" mat-button>Edit</button>
                <button (click)="removeJob(job)" *ngIf="canDelete" mat-button>Delete</button>
              </div>
            </mat-card-actions>
        </mat-card>
    </div>
</div>