import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { Candidate } from '../candidate/candidate';
import { CandidateService } from '../candidate/candidate.service';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {
  canDelete = false;
  canEdit = false;
  candidates: Candidate[];
  downloadUrl: string;
  user: any;
  ref: any;
  
  @ViewChild('searchbar') searchbar!: ElementRef;
  searchText = '';
  isEdit = false;
   
  toggleSearch:boolean = false;

  constructor(
    private candidateService: CandidateService, private router: Router, private authService: AuthService, private afStorage: AngularFireStorage) { 
    this.candidates = [];
    this.downloadUrl = '';
  }

  ngOnInit() {
    this.candidateService.getCandidates().subscribe(result => {
      this.candidates = result;
    });
    this.authService.user$.subscribe(result => {
      this.user = result;
      this.canDelete = this.authService.canDelete(this.user);
      this.canEdit = this.authService.canEdit(this.user);
    });
  }

  getUrl(id: string) {
    this.ref = this.afStorage.ref(id);
    this.ref.getDownloadURL().subscribe( (result: string) => {
      this.downloadUrl = result;
      window.open(this.downloadUrl, "_blank");
    });
  }
  
  openSearch(){
    this.toggleSearch = true;
    this.searchbar.nativeElement.focus();
  }

  searchClose(){
    this.searchText = '';
    this.toggleSearch = false;
  }
  
  navigateTo(url: string){
    this.router.navigate([url]);
  }
  
}
