<!-- Here's a material list, hook up to it and the data will show.
  <mat-nav-list>
  <mat-list-item *ngFor="let candidate of candidates">
     <a matLine [routerLink]="'/candidates/'candidate.id" >{{ candidate.displayName }}</a>
     <button mat-icon-button (click)="delete(candidate.id)">
        <mat-icon>remove</mat-icon>
     </button>
  </mat-list-item>
</mat-nav-list>
-->
<div fxFlexFill cdkScrollable class="content">
   <div fxLayout="row">
      <!--<mat-toolbar class="main-header mat-elevation-z4" [class.hidden]="toggleSearch">
         <div fxFlex></div>
         <button mat-icon-button (click)="openSearch()">
            <mat-icon>search</mat-icon>
         </button>
      </mat-toolbar>

      <mat-toolbar class="search-block mat-elevation-z4" [class.active]="toggleSearch">
         <mat-toolbar-row style="padding: 0 5px;">
            <button class="search-icon" mat-icon-button disabled>
               <mat-icon>search</mat-icon>
            </button>
            <input class="search-control" type="text" placeholder="Search" [(ngModel)]="searchText" name="search"
               #searchbar>
            <button mat-button mat-icon-button (click)="searchClose()" style="color: black">
               <mat-icon>close</mat-icon>
            </button>
         </mat-toolbar-row>
      </mat-toolbar>-->
   </div>
   <mat-list class="mat-elevation-z4" style="margin-top: 48px; background-color: white;margin:8px;">
      <mat-list-item *ngFor="let candidate of candidates | filter: searchText" style="margin: 16px;">
         <mat-icon matListAvatar>person</mat-icon>
         <div mat-line>{{candidate.firstName}} {{candidate.lastName}}</div>
         <div mat-line>{{candidate.email}}</div>
         <div mat-line>{{candidate.phone}}</div>
         <div mat-line>Applying for: {{candidate.jobTitle}}</div>
         <!-- <button (click)="navigateTo('resume/'+candidate.id)" *ngIf="canEdit" mat-button>Edit</button>
               <button *ngIf="canDelete" mat-button>Delete</button> -->
         <button mat-button (click)="getUrl(candidate.uploadedFileId)">View Resume</button>
         <mat-divider></mat-divider>
      </mat-list-item>
   
   </mat-list>
</div>