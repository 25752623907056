import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { FormBuilder } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private authService: AuthService, private fb: FormBuilder, private afStorage: AngularFireStorage) {
  }

  user$: any;
  userProfile$: any;
  ref: any;
  task: any;
  uploadProgress:any;
  downloadURL= "";
  resumeID = "";

  onProfileSubmit(){
    this.profileForm.patchValue({
      uid: this.user$.uid, 
      resumeID: this.resumeID,
      resumeURL: this.downloadURL
    });
    this.userProfile$.resumeID = this.resumeID
    this.userProfile$.resumeURL = this.downloadURL
    this.authService.updateUserProfileData(this.profileForm.value);
    alert("Profile Saved!");
  }

  profileForm = this.fb.group({
    uid: [null],
    name: [null],
    email: [null],
    phone: [null],
    age: [null],
    addressLine1: [null],
    addressLine2: [null],
    city: [null],
    state: [null],
    zipcode: [null],
    gender: [null],
    personalURL: [null],
    resumeID: [null],
    resumeURL: [null],
    eligibleToWork: [null]
  });

  ngOnInit(): void {
    this.authService.user$.subscribe(result => {
      this.user$ = result;
    });
    this.authService.userProfile$.subscribe(result => {
      this.userProfile$ = result;
      this.profileForm = this.fb.group({
        uid: [this.user$.uid],
        name: [this.userProfile$.name],
        email: [this.userProfile$.email],
        phone: [this.userProfile$.phone],
        age: [this.userProfile$.age],
        addressLine1: [this.userProfile$.addressLine1],
        addressLine2: [this.userProfile$.addressLine2],
        city: [this.userProfile$.city],
        state: [this.userProfile$.state],
        zipcode: [this.userProfile$.zipcode],
        gender: [this.userProfile$.gender],
        personalURL: [this.userProfile$.personalURL],
        resumeID: [this.userProfile$.resumeID],
        resumeURL: [this.userProfile$.resumeURL],
        eligibleToWork: [this.userProfile$.eligibleToWork]
      });
      this.resumeID = this.userProfile$.resumeID;
    });
  }

  upload(event: any) {
    // generating random ID for the resume file to associate with the candidate
    const randomId = Math.random().toString(36).substring(2);
    this.resumeID = randomId;
    
    this.ref = this.afStorage.ref(randomId);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL(); // <-- Here the downloadURL is available.
        this.ref.getDownloadURL().subscribe( (result: string) => {

          this.downloadURL = result;
          this.profileForm.patchValue({
            resumeURL: this.downloadURL
          });
          console.log('downloadURL: ', this.downloadURL);
        },
          (error: any) => {
            console.log(error);
          }
        );
      })
    ).subscribe();
  }

  getUrl(id: string) {
    this.ref = this.afStorage.ref(id);
    this.ref.getDownloadURL().subscribe( (result: string) => {
      this.downloadURL = result;
      window.open(this.downloadURL, "_blank");
    });
  }

}
