export class Candidate {
    id: string = '';
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    phone: string = '';
    jobTitle: string = '';
    jobId: string = '';
    uploadedFileId: string = '';
    hasPreviousExperience: boolean = false;
    employmentType: string = '';
    isLegallyEligibileToWork: boolean = false;
    isOver18: boolean = false;
}
