<mat-card>
    <mat-list-item>
        <mat-card-header>
            <mat-card-title>Your Profile Details</mat-card-title>
        </mat-card-header>
    </mat-list-item>
</mat-card>
<mat-card class="content">
    <mat-vertical-stepper [linear]="true" #stepper>
        <mat-step label="Profile Details" [stepControl]="profileForm">
            <form [formGroup]="profileForm" (ngSubmit)="onProfileSubmit()">
                <mat-card-content>
                    <mat-form-field *ngHide="true">
                        <input matInput placeholder="UID" formControlName="uid">
                    </mat-form-field>
                    <div class="row">
                        <mat-form-field class="full-width">
                            <strong>Full Name:</strong>
                            <input matInput formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="two-col">
                            <strong>Email Address:</strong>
                            <input matInput formControlName="email">
                        </mat-form-field>
                        <mat-form-field class="two-col">
                            <strong>Phone Number:</strong>
                            <input matInput formControlName="phone">
                        </mat-form-field>
                    </div>
                    <div class="row">
                            <mat-form-field class="two-col">
                                <strong>Age:</strong>
                                <input matInput formControlName="age">
                            </mat-form-field>
                            <mat-form-field class="two-col">
                                <strong>Gender:</strong>
                                <mat-select formControlName="gender">
                                    <mat-option [value]="'Male'">Male</mat-option>
                                    <mat-option [value]="'Female'">Female</mat-option>
                                    <mat-option [value]="'Other'">Other</mat-option>
                                </mat-select>
                            </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field>
                            <strong>Address Line 1:</strong>
                            <input matInput formControlName="addressLine1">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field>
                            <strong>Address Line 2:</strong>
                            <input matInput formControlName="addressLine2">
                        </mat-form-field>
                    </div>
                    <div class="row">
                            <mat-form-field class="three-col">
                                <strong>City:</strong>
                                <input matInput formControlName="city">
                            </mat-form-field>
                            <mat-form-field class="three-col">
                                <strong>State:</strong>
                                <input matInput formControlName="state">
                            </mat-form-field>
                            <mat-form-field class="three-col">
                                <strong>Zipcode:</strong>
                                <input matInput formControlName="zipcode">
                            </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field>
                            <strong>Personal URL/Website (e.g. LinkedIn):</strong>
                            <input matInput formControlName="personalURL">
                        </mat-form-field>
                    </div>
                    <strong>Resume</strong>
                    <div class="row" *ngIf="this.resumeID" style = "margin-top: 5px; margin-bottom: 5px;">
                        <button type="button" (click)="getUrl(this.resumeID)">View Your Resume</button>
                    </div>
                    <div class="row" style = "margin-top: 5px; margin-bottom: 5px;">
                        <strong>Upload New Resume: </strong>
                        <input type="file" (change)="upload($event)" accept=".png,.jpg,.doc,.docx,.pdf" />
                        <mat-progress-bar [value]="(uploadProgress | async)" style="margin-top: 16px">
                        </mat-progress-bar>
                    </div>
                    <div class="row">
                        <strong>Are You Legally Eligible to Work in the US?:</strong>
                        <mat-form-field style = "width: 10%;">
                            <mat-select formControlName="eligibleToWork">
                                <mat-option [value]="'Yes'">Yes</mat-option>
                                <mat-option [value]="'No'">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <button style = "margin-top: 5px; margin-bottom: 5px;" type="submit">Save Profile</button>
            </form>
        </mat-step>
    </mat-vertical-stepper>
</mat-card>