import { Injectable } from '@angular/core';
import { Job } from './job';
import { JobTitles } from './job-titles';
import { AngularFirestore, CollectionReference, AngularFirestoreCollection, AngularFirestoreCollectionGroup } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Location } from '../services/location.model'

@Injectable({
  providedIn: 'root'
})
export class JobService {
  jobTitlesRef: any;
  jobsRef: any;
  locationsRef: any;

  constructor(private db: AngularFirestore) { 
    this.jobsRef = db.collection('jobs');
    this.locationsRef = db.collection('locations');
    this.jobTitlesRef = db.collection('jobTitles');
  }

  getJobTitles(): Observable<JobTitles[]> {
    return this.jobTitlesRef.valueChanges({idField: 'id'}) as Observable<JobTitles[]>;
  }

  getJob(id: string): Observable<Job> {
    if (!id) return new Observable<Job>();
    return this.jobsRef.doc(id).valueChanges({ idField: 'id' });
  }
  
  getJobs(): Observable<Job[]> {
    return this.jobsRef.valueChanges({idField: 'id'}) as Observable<Job[]>;
  }
  
  async addJob(job: Job) {
    const doc_ref = await this.jobsRef.add(job);
    console.log(doc_ref);
    job.id = doc_ref.id;
    this.updateJob(job);
  }

  async addJobTitle(jobTitle: JobTitles) {
    return this.jobTitlesRef.add({...jobTitle});
  }

  removeJob(job: Job) {
    this.jobsRef.doc(job.id).delete();
  }

  removeJobTitle(jobTitle: JobTitles) {
    this.jobsRef.doc(jobTitle.id).delete();
  }

  updateJob(job: Job): void {
    this.jobsRef.doc(job.id).set(job, { merge: true });
  }

  updateJobTitle(jobTitle: JobTitles): void {
    this.jobTitlesRef.doc(jobTitle.id).set(jobTitle, { merge: true });
  }

  getLocations(): Observable<Location[]> {
    return this.locationsRef.valueChanges({idField: 'id'}) as Observable<Location[]>;
  }

  getLocation(id: string): Observable<Location> {
    if (!id) return new Observable<Location>();
    return this.locationsRef.doc(id).valueChanges({ idField: 'name' });
  }

  removeLocation(location: Location) {
    this.locationsRef.doc(location.id).delete();
  }

  updateLocation(location: Location): void {
    this.locationsRef.doc(location.id).set(location, { merge: true });
  }

}
