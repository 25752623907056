import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from './shared/safe.pipe';
import { OrderPipe } from './shared/order.pipe';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatMenuModule} from '@angular/material/menu';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthGuard } from './auth.guard';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { JobComponent } from './job/job.component';
import { ResumeComponent } from './resume/resume.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProfileComponent } from './profile/profile.component';
import { JobsComponent } from './jobs/jobs.component';
import { CandidatesComponent } from './candidates/candidates.component';
import { FilterPipe } from './filter.pipe';
import { CandidateComponent } from './candidate/candidate.component';
import { AuthService } from './core/auth.service';

const config = {
  apiKey: "AIzaSyDBKtctFjDpab3wFH66XxRfFGBG28NOxUI",
  authDomain: "jobs.nazarianglobalenterprises.com",
  projectId: "nge-jobs-fd30d",
  storageBucket: "nge-jobs-fd30d.appspot.com",
  messagingSenderId: "193885058975",
  appId: "1:193885058975:web:9688f731e2b2a09236efaa",
  measurementId: "G-SX5JK9FBMB"
};

@NgModule({
  declarations: [
    AppComponent,
    JobComponent,
    ResumeComponent,
    SafePipe,
    OrderPipe,
    ProfileComponent,
    JobsComponent,
    CandidatesComponent,
    FilterPipe,
    CandidateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTabsModule,
    MatGridListModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatStepperModule,
    MatMenuModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatProgressBarModule,
    CoreModule
  ],
  providers: [
    AuthGuard,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
