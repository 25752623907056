export class Job {
    id: string = '';
    title: string = '';
    intro: string = '';
    imageUrl: string = '';
    responsibilities: string = '';
    additionalResponsibilities: string = '';
    location: string = '';
    createdBy: string = '';
    datePosted: Date = new Date();
}
