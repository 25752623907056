import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { JobService } from '../job/job.service';
import { Job } from '../job/job';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { User } from '../services/user.model';
import { UserProvidedConfigToken } from 'ngx-auth-firebaseui';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit{
  canDelete = false;
  canEdit = false;
  jobs: Job[];
  user: any;
  
  @ViewChild('searchbar') searchbar!: ElementRef;
  searchText = '';
  isEdit = false;
   
  toggleSearch:boolean = false;

  constructor(private jobService: JobService, private router: Router, private authService: AuthService){
    this.jobs = [];
  } 
  
  ngOnInit() {
    this.jobService.getJobs().subscribe(result => {
      this.jobs = result;
    });
    this.authService.user$.subscribe(result => {
      this.user = result;
      this.canDelete = this.authService.canDelete(this.user);
      this.canEdit = this.authService.canEdit(this.user);
    });
  }

  openSearch(){
    this.toggleSearch = true;
    this.searchbar.nativeElement.focus();
  }

  searchClose(){
    this.searchText = '';
    this.toggleSearch = false;
  }
  
  removeJob(job: Job) {
    if (confirm("Delete this job posting?")) {
      this.jobService.removeJob(job);
    }
  }

  navigateTo(url: string){
    this.router.navigate([url]);
  }
  
}
  