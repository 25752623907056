<form [formGroup]="jobEntryForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card class="content shipping-card">
    <mat-card-header>
      <mat-card-title>Job Entry Information</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" class="saneMargins">
        <mat-form-field fxLayout="row">
          <mat-select placeholder="Job Title" formControlName="jobTitle">
            <mat-option *ngFor="let jobTitle of jobTitles" [value]="jobTitle.name">
              {{ jobTitle.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="jobEntryForm.controls['jobTitle'].hasError('required')">
            Job Title is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxLayout="row">
          <input matInput placeholder="New Title" formControlName="newJobTitle">
          <button type="button" mat-raised-button matSuffix (click)="saveJobTitle()">Save Job Title</button>
        </mat-form-field>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <textarea matInput placeholder="Job Intro" formControlName="intro"></textarea>
              <mat-error *ngIf="jobEntryForm.controls['intro'].hasError('required')">
                Intro is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <textarea matInput placeholder="Job Responsibilities" formControlName="responsibilities"></textarea>
              <mat-error *ngIf="jobEntryForm.controls['responsibilities'].hasError('required')">
                Responsibilities is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <textarea matInput placeholder="Additional Responsibilities"
                formControlName="additionalResponsibilities"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <mat-select placeholder="Location" formControlName="location">
                <mat-option *ngFor="let location of locations" [value]="location.restaurantName + ' ' + location.city + ', ' + location.stateAbbr">
                  <strong>{{location.restaurantName}}</strong> {{location.city}}, {{location.stateAbbr}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="jobEntryForm.controls['location'].hasError('required')">
                Location is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="full-width">
              <input matInput placeholder="Created By" formControlName="createdBy">
              <mat-error *ngIf="jobEntryForm.controls['createdBy'].hasError('required')">
                Created By is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <input matInput placeholder="Date Posted" type="date" formControlName="datePosted">
              <mat-error *ngIf="jobEntryForm.controls['datePosted'].hasError('required')">
                Date Posted is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="saneMargins">
      <button [disabled]="!canDelete" mat-raised-button color="primary" type="submit">Submit</button>
    </mat-card-actions>
  </mat-card>
</form>