import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobComponent } from './job/job.component';
import { AuthGuard } from './auth.guard'; 
import { ResumeComponent } from './resume/resume.component';
import { AppComponent } from './app.component';
import { JobsComponent } from './jobs/jobs.component';
import { CandidateComponent } from './candidate/candidate.component';
import { CandidatesComponent } from './candidates/candidates.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {
    path: 'job/:id',
    component: JobComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'job',
    component: JobComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jobs',
    component: JobsComponent
  },
  {
    path: 'candidate',
    component: CandidateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'candidates',
    component: CandidatesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'resume/:id',
    component: ResumeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'resume',
    component: ResumeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'jobs'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
