import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { User } from '../services/user.model';
import { auth } from 'firebase/app';
import { UserProfile } from '../services/userprofile.model';

@Injectable()
export class AuthService {

  user$: Observable<any>;
  userProfile$: Observable<any>;

  constructor(private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router) {
    //// Get auth data, then get firestore user document || null
    this.user$ = this.afAuth.authState
      .pipe(switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
          return of(null);
        }
      }))
      this.userProfile$ = this.afAuth.authState
      .pipe(switchMap(user => {
        if (user) {
          return this.afs.doc<UserProfile>(`userProfiles/${user.uid}`).valueChanges()
        } else {
          return of(null);
        }
      }))
  }



  ///// Login/Signup //////

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider()
    return this.oAuthLogin(provider);
  }

  public newSignIn() {
    const provider = new auth.EmailAuthProvider();
    console.log(provider);
    return this.oAuthLogin(provider);
  }

  public googleSignIn() {
    const provider = new auth.GoogleAuthProvider();
    console.log(provider);
    return this.oAuthLogin(provider);
  }

  async getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  private oAuthLogin(provider: any) {
    return this.afAuth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user)
      });
  }


  ///// Role-based Authorization //////

  // If we change the names of the roles update here too

  canRead(user: User): boolean {
    // const allowed = ['admin', 'editor', 'subscriber'];
    // return this.checkAuthorization(user, allowed);
    return user?.roles?.subscriber ?? false;
  }

  canEdit(user: User): boolean {
    // const allowed = ['admin', 'editor'];
    // return this.checkAuthorization(user, allowed);
    return user?.roles?.editor ?? false;
  }

  canDelete(user: User): boolean {
    // const allowed = ['admin'];
    // return this.checkAuthorization(user, allowed);
    return user?.roles?.admin ?? false;
  }



  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      // if ( user.roles ) {
      //   return true;
      // }

    }
    return false;
  }

  private updateUserData(user: any) {
    // Sets user data to firestore on login
    // If we change the names of the roles update here too
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      roles: {
        subscriber: true
      },
      photoURL: user.photoURL
    }
    return userRef.set(data, { merge: true })
  }

  updateUserProfileData(user: any){
    const userProfileRef: AngularFirestoreDocument<any> = this.afs.doc(`userProfiles/${user.uid}`);
    const data: UserProfile = {
      uid: user.uid,
      name: user.name,
      email: user.email,
      phone: user.phone,
      age: user.age,
      gender: user.gender,
      addressLine1: user.addressLine1,
      addressLine2: user.addressLine2,
      city: user.city,
      state: user.state,
      zipcode: user.zipcode,
      personalURL: user.personalURL,
      resumeID: user.resumeID,
      resumeURL: user.resumeURL,
      eligibleToWork: user.eligibleToWork
    }
    return userProfileRef.set(data, {merge: true})
  }


  async signOut() {
    await this.afAuth.signOut();
    return this.router.navigate(['/']);
  }
}
