import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { Job } from './job';
import { JobTitles } from './job-titles';
import { JobService } from './job.service';
import { Location } from '../services/location.model'

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit {

  @Input() job: Job;
  @Input() edit: boolean;
  @Output() jobSaved = new EventEmitter<Job>();

  jobEntryForm = this.fb.group({
    newJobTitle: [null],
    jobTitle: [null, Validators.required],
    intro: [null, Validators.required],
    responsibilities: [null, Validators.required],
    additionalResponsibilities: null,
    location: [null, Validators.required],
    createdBy: [null, Validators.required],
    datePosted: [null, Validators.required]
  });
  jobTitles: JobTitles[];
  locations: Location[];
  newJobTitle: JobTitles;
  newLocation: Location;
  jobId: string = '';
  canDelete = false;
  user: any;

  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private jobService: JobService, private authService: AuthService) {
    this.job = new Job;
    this.jobTitles = [];
    this.locations = [];
    this.newJobTitle = new JobTitles;
    this.newLocation = new Location;
    this.edit = false;
  }

  ngOnInit() {
    this.jobService.getJobTitles().subscribe(result => {
      this.jobTitles = result;
      this.newJobTitle.id = this.jobTitles.length + 1;
    });
    this.authService.user$.subscribe(result => {
      this.user = result;
      this.job.createdBy = this.user.displayName ?? '';
      this.canDelete = this.authService.canDelete(this.user);
    });
    this.jobService.getLocations().subscribe(result => {
      this.locations = result;
      this.newLocation.id = this.locations.length + 1 as unknown as string
    });
    this.activatedRoute.params.subscribe(params => {
      this.jobId = params['id'];
      this.jobService.getJob(this.jobId).subscribe(result => {
        this.job = result;
        this.edit = true;
        this.initForm();
      });
    });
    // this.initForm();
  }

  initForm() {
    this.jobEntryForm = this.fb.group({
      id: [this.job.id],
      newJobTitle: [null],
      jobTitle: [this.job.title, Validators.required],
      intro: [this.job.intro, Validators.required],
      responsibilities: [this.job.responsibilities, Validators.required],
      additionalResponsibilities: ['', null],
      location: [this.job.location, Validators.required],
      createdBy: [this.job.createdBy, Validators.required],
      datePosted: [this.job.datePosted, Validators.required]
    });
  }

  saveJobTitle() {
    if (confirm("Create new job title?")){
      this.newJobTitle.name = this.jobEntryForm.value.newJobTitle;
      this.jobEntryForm.value.jobTitle = this.jobEntryForm.value.newJobTitle;
      try {
        this.jobService.addJobTitle(this.newJobTitle);
        this.jobEntryForm.value.jobTitle = this.newJobTitle.name;
        alert("Job Title Saved.")
      }
      catch (error) {
        alert(error.name);
      }
    }
  }

  onSubmit(): void {
    this.job = this.jobEntryForm.value;
    this.job.title = this.jobEntryForm.value.jobTitle;
    this.job.location = this.jobEntryForm.value.location;

    try {
      if (!this.edit){
        this.jobService.addJob(this.job);
      }
      else {
        this.jobSaved.emit(this.job);
        this.jobService.updateJob(this.job);
      }
      alert("Job Entry Saved.");
      this.jobEntryForm.reset();
    } 
    catch (error) {
      alert(error.name);
    }
  }
}
