import { Component, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize, map } from 'rxjs/operators';
import { Candidate } from '../candidate/candidate';
import { CandidateService } from '../candidate/candidate.service';
import { Job } from '../job/job';
import { JobService } from '../job/job.service';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent {
  // @Input() candidate: Candidate;
  @Input() job: Job;
  // @Input() edit: boolean;

  hasJobId: boolean;
  ref: any;
  task: any;
  uploadProgress:any;
  downloadURL= "";
  uploadURL: any;
  canSubmit = true;
  // candidateId: string = '';
  candidate: Candidate;
  jobId: string = '';
  candidateService: CandidateService;
  candidateForm = this.fb.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, Validators.required],
    phone: [null, Validators.required],
    jobTitle: [null, Validators.required]
  });
  resumeForm = this.fb.group({
    // uploadedFile: [null, Validators.required]
  });
  moreInfoForm = this.fb.group({
    previousExperience: [null, Validators.required],
    employmentType: [null, Validators.required],
    eligibility: [null, Validators.required],
    isOver18: [null, Validators.required]
  });
  jobTitles: any;

  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private afStorage: AngularFireStorage, private jobService: JobService, private cService: CandidateService) {
    jobService.getJobTitles().subscribe(result => {
      this.jobTitles = result;
    });
    this.candidateService = cService;
    this.candidate = new Candidate;
    this.job = new Job;
    // this.edit = false;
    this.hasJobId = false;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.jobId = params['id'];
      this.jobService.getJob(this.jobId).subscribe(result => {
        this.job = result;
        this.hasJobId = true;
        this.candidate.jobTitle = this.job.title;
      });
    });
  }

  initForm() {
    // this.candidateForm = this.fb.group({
    //   id: [this.candidate.id],
    //   firstName: [null, Validators.required],
    //   lastName: [null, Validators.required],
    //   email: [null, Validators.required],
    //   phone: [null, Validators.required],
    //   jobTitle: [null, Validators.required]
    // });
    // this.resumeForm = this.fb.group({
    //   // uploadedFile: [null, Validators.required]
    // });
  }

  onCandidateSubmit(): void {
    this.candidate = this.candidateForm.value;
    this.candidate.jobId = this.jobId;
  }

  upload(event: any) {
    this.canSubmit = false;
    // generating random ID for the resume file to associate with the candidate
    const randomId = Math.random().toString(36).substring(2);
    this.candidate.uploadedFileId = randomId;
    
    this.ref = this.afStorage.ref(randomId);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL(); // <-- Here the downloadURL is available.
        this.ref.getDownloadURL().subscribe( (result: string) => {

          this.downloadURL = result;
          this.candidateForm.patchValue({
            coverImageUrl: this.downloadURL
          });
          console.log('downloadURL: ', this.downloadURL);
          this.canSubmit = true;
        },
          (error: any) => {
            console.log(error);
            this.canSubmit = true;
          }
        );
      })
    ).subscribe();

    alert("Candidate Info Saved.");
    // this.candidateForm.reset();
    // this.resumeForm.reset();
  }

  onFullSubmit() {
    this.candidate.hasPreviousExperience = this.moreInfoForm.value.previousExperience == "Yes";
    this.candidate.employmentType = this.moreInfoForm.value.employmentType;
    this.candidate.isLegallyEligibileToWork = this.moreInfoForm.value.eligibility == "Yes";
    this.candidate.isOver18 = this.moreInfoForm.value.isOver18 == "Yes";
    try {
      this.candidateService.addCandidate(this.candidate);
    } 
    catch (error) {
      alert(error.name);
    }
  }

}
