<div class="mat-typography light-blue" style="height: 100vh">
  <mat-sidenav-container class="sidenav-container gradient earl-gray" style="height: 100%;">
    <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="'over'">
      <mat-nav-list fxLayout="column" fxFlexFill style="padding-top:0">
        <mat-toolbar class="gradient sharp-glass">
          <ng-container *ngIf="auth.user$ | async; then authenticated else guest"></ng-container>
        </mat-toolbar>
        <section *ngIf="!userMenuShow" fxLayout="column">
          <h3 matSubheader>Actions</h3>
          <mat-divider></mat-divider>
          <a mat-list-item routerLinkActive="active-link" routerLink="/jobs" (click)="drawer.close()">
            <mat-icon matListIcon>search</mat-icon>
            Find a Job
          </a>
          <div *ngIf="auth.user$ | async">
            <div *ngIf="auth.canRead(auth.user$ | async)">
              <a mat-list-item routerLinkActive="active-link" routerLink="/resume" (click)="drawer.close()">
                <mat-icon matListIcon>file_upload</mat-icon>
                Upload a Resume
              </a>
            </div>
            <div *ngIf="auth.canEdit(auth.user$ | async)">
              <a mat-list-item routerLinkActive="active-link" routerLink="/candidates" (click)="drawer.close()">
                <mat-icon matListIcon>visibility</mat-icon>
                View Candidates
              </a>
            </div>
            <div *ngIf="auth.canDelete(auth.user$ | async)">
              <a mat-list-item routerLinkActive="active-link" routerLink="/job" (click)="drawer.close()">
                <mat-icon matListIcon>post_add</mat-icon>
                Post a Job
              </a>
            </div>
            <a mat-list-item (click)="userMenuShow = !userMenuShow">
              <mat-icon matListIcon>person_outline</mat-icon>
              User Info
            </a>
          </div>

        </section>
        <section *ngIf="userMenuShow">
          <h3 matSubheader>User Account</h3>
          <mat-divider></mat-divider>
          <div *ngIf="auth.user$ | async">
            <a mat-list-item routerLinkActive="active-link" routerLink="/profile" (click)="drawer.close()">
              <mat-icon matListIcon>edit</mat-icon>
              User Profile
            </a>
            <a mat-list-item (click)="userMenuShow = !userMenuShow">
              <mat-icon matListIcon>work_outline</mat-icon>
              Job Info
            </a>
            <a mat-list-item (click)="auth.signOut();drawer.close();userMenuShow = false">
              <mat-icon matListIcon>subdirectory_arrow_right</mat-icon>
              Log Out
            </a>
          </div>
        </section>
        <div fxFlex></div>
        <mat-divider></mat-divider>
        <div style="margin-top:10px;">
          <div matSubheader fxLayout="horizontal"> <span fxFlex>About</span>
            <span>Copyright Nazarian Global Enterprises</span>
          </div>
          <mat-divider></mat-divider>
          <a mat-list-item routerLinkActive="active-link" (click)="drawer.close()">
            <mat-icon matListIcon>flag</mat-icon>
            Privacy Policy
          </a>
          <a mat-list-item href="http://www.nazarianglobalenterprises.com" (click)="drawer.close()">
            <mat-icon matListIcon>local_offer</mat-icon>
            FAQ
          </a>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="max-width: 100%;overflow-x: hidden;" fxLayout="column">
      <mat-toolbar class="dark">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon" >menu</mat-icon>
        </button>
        <a href="https://www.nazarianglobalenterprises.com/" style="margin-top: 8px;">
          <img  style="max-height: 42px;" src="assets/nge-color.png">
        </a>
      </mat-toolbar>
      <div>
        <router-outlet></router-outlet>
      </div>
      <div fxFill></div>
      <footer fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="space-between start" class="dark" style="padding: 32px" >
        <div fxLayout="column" fxLayoutAlign="start start">
          <img src="https://lirp.cdn-website.com/20839ba2/dms3rep/multi/opt/NGE-Logo-Client-Copy_Artboard-5-copy-3-6cd731ab-203w.png"  class="" height="72.1875" width="169.875">
          <p>Loyalty, Opportunity, Responsibility</p>
          <p>The NGE Way</p>
        </div>
        <div fxLayout="column">
          <h5>CONTACT</h5>
          <p>713-722-8866</p>
          <p>hr@ngetexas.com</p>
          <p>10894 Shadow Wood Dr</p>
          <p>Houston, TX 77043</p>
        </div>
        <div fxLayout="column">
          <h5>INTERVIEW HOURS</h5>
          <p>Mon - Fri   9:00 am - 6:00 pm</p>
          <p>Sat - Sun   Closed</p>
        </div>
        <div fxLayout="column">
          <h5>CONNECT</h5>
          <a href="https://www.instagram.com/ihop/" target="_blank" dmle_dont_remove="target" dm_dont_rewrite_url="true"> 
          </a> 
          <a href="https://www.linkedin.com/company/nazarian-global-enterprises" target="_blank" dmle_dont_remove="target" dm_dont_rewrite_url="true">
          </a> 
        </div>
      </footer>

    </mat-sidenav-content>
  </mat-sidenav-container>



  <ng-template #guest>
    <mat-toolbar-row style="height:4px;">
      <button mat-button (click)="auth.googleSignIn()">Sign In</button>
    </mat-toolbar-row>
  </ng-template>

  <ng-template #authenticated>
    <mat-toolbar-row style="height:4px;" *ngIf="auth.user$ | async as user">
      <small>{{ user.displayName }}</small>
    </mat-toolbar-row>
    <mat-toolbar-row (click)="userMenuShow=!userMenuShow" fxLayout="row" style="cursor:pointer;">
      <small fxFlex *ngIf="auth.user$ | async as user">{{ user.email}}</small>
      <mat-icon>expand_more</mat-icon>
    </mat-toolbar-row>
  </ng-template>